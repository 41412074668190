import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const BlogDetails = () => {
  return (
    <>
      <div className="section sub-container">
        <div className="flex space-between">
          <div>
            <h3>Sensitive Teeth </h3>
            <p style={{ maxWidth: "650px" }}>
              Sensitive teeth can be a real pain in the mouth. Typically,
              drinking or eating something cold, hot, or acidic causes pain or
              sensitivity to call your attention to your teeth needing some TLC.
              There are many different causes of this pain. Fortunately, there
              are some things you can do at home to alleviate the discomfort
              until you can see the dentist and find out the cause and best
              long-term treatment.
            </p>
          </div>
          <div style={{ width: "40%" }}>
            <StaticImage src="../../images/emergency.png" alt="emergency" />
          </div>
        </div>
        <div className="section">
          <p>
            Some foods and beverages are more likely to cause tooth sensitivity
            than others. In particular, acid foods and beverages are often the
            culprits of tooth sensitivity. Acidic foods include things like
            coffee, sodas, fruit, and pickles, to name just a few. When
            experiencing a sensitivity event, it’s best to avoid acidic foods
            until your teeth are less sensitive.
          </p>
          <p>
            Another frequent culprit of increased sensitivity is a build-up of
            bacteria in your mouth. Good oral hygiene from brushing and flossing
            is critical to keeping harmful bacteria at bay, but you can do other
            things at home to help maintain oral health. Remember to brush your
            teeth at least twice a day using a soft toothbrush. Firm
            toothbrushes are not advised for anyone as they can damage your
            teeth and gums, so stick to the soft bristles, and your gums will
            thank you. Floss daily and keep your routine dental appointments for
            optimal oral health.
          </p>
          <p>
            Here are a few dental care tips and at-home remedies that can help
            soothe sensitive teeth until you see your dentist.
          </p>
          <p>
            Clove and clove oil are anti-inflammatories, analgesic, and
            germicidal; no wonder they have been used for generations to
            alleviate tooth pain and sensitivity. When used as an extract and
            swished around your mouth, clove oil can calm the sensitivity of
            your teeth by its numbing effect. To improve the analgesic effect of
            the clove oil, add some orange essential oil to the mixture before
            use. These oils are natural and potent; use a few drops mixed in a
            carrier oil such as avocado or coconut oil. Spit the oil solution
            out after swishing it around your mouth for a few minutes.
          </p>
          <p>
            Liquified coconut oil pulling is another excellent option for
            keeping harmful bacteria under control in your mouth. This practice
            is ancient; some cultures have practiced it daily for centuries for
            its many health benefits. Coconut oil serves many purposes,
            including breaking down plaque on teeth and removing toxins and
            germs from the surface of gums. This combination is very beneficial
            to oral health, leading to healthier teeth and gums. Many people do
            oil pulling daily, first thing in the morning, to start their day
            right.{" "}
          </p>
          <p>
            An alternative to the use of oil pulling to reduce damaging oral
            bacteria is a basic salt water mouthwash that you can easily make at
            home with regular table salt. Take a quarter cup of warm water and
            add a half teaspoon of salt, mixing until the salt dissolves. Use
            this mixture as a mouthwash daily after you brush your teeth. It
            will help balance the PH level in your mouth, leaving it more
            alkaline, which significantly reduces the ability of bacteria to
            grow.
          </p>
          <p>
            Another standard recommendation is the use of widely available
            commercial toothpaste made explicitly for tooth sensitivity. Most of
            these kinds of toothpaste contain an ingredient that temporarily
            fills the microscopic holes in the enamel and dentin of your teeth,
            lessening the sensitivity. You may use these specialized toothpaste
            daily.
          </p>
          <p>
            Lastly, but perhaps most importantly, see your dentist to be
            assessed for gum disease as soon as possible. Receding or diseased
            gums is a major cause of tooth sensitivity. As gums are affected by
            periodontal disease, they break down and pull away from the tooth
            base, exposing parts of the tooth that do not have enamel on them.
            Early detection is critical for the successful treatment of gum
            disease, and if treated in the early stages, it may be reversed.
            Time is of the essence when addressing the root cause of tooth
            sensitivity. Call Dinka Dental today to schedule an appointment to
            get the relief and treatment you need.
          </p>
        </div>
      </div>
    </>
  )
}

export default BlogDetails
