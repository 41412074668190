import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BannerBlog from "../../components/blog/blogArticleBanner.component"
import BlogDetails from "../../components/blog/blogDetails.component"

const Services = () => {
  return (
    <div>
      <Layout>
        <Seo title="Sensitive Teeth" />
        <BannerBlog title="Blog - Sensitive Teeth" />
        <BlogDetails />
      </Layout>
    </div>
  )
}

export default Services
